import Alpine from 'alpinejs'
import intersect from '@alpinejs/intersect'
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import fitvids from 'fitvids';

Alpine.plugin(intersect)

window.Alpine = Alpine


Alpine.start()

lazySizes.init();


if (document.getElementsByClassName('myvid') != null) {
    var mv = null;
    mv = document.getElementsByClassName('myvid');

    fitvids()
}

// $(document).on('lazybeforesizes', function(e){
//     //use width of parent node instead of the image width itself
//     e.detail.width = $(e.target).closest(':not(picture)').innerWidth() || e.detail.width;
//     console.log("fired refresh");
// });